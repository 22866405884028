* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100vh;
  font-size: 10px;
  line-height: 1.2;
}

body {
  margin: 0;
  font-family: Walsheim, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  margin-bottom: 1em;
}

p:last-of-type {
  margin: 0;
}

.overflow-scroll {
  overflow: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}

.single-line-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
