:root {
  --blue:            #0387D1;
  --dark-blue:       #00214C;
  --light-blue:      #00B0FF;
  --lighter-blue:    #82D4F5;
  --lighter-blue-50: rgba(130, 212, 245, 50%);
  --lightest-blue:   #E0F5FF;
  --text-blue:       var(--lighter-blue);
  --text-blue-50:    rgba(3, 135, 209, 50%);

  --turquoise:       #26C0D4;

  --orange:          #FF633D;
  --dark-orange:     #CC2440;
  --light-orange:    #F26441;

  --yellow:          #F6AB1B;

  --red:             red;

  --black:           #000000;
  --white:           #FFFFFF;

  --invest:          var(--light-orange);
  --cofound:         var(--turquoise);
  --collaborate:     var(--dark-orange);
  
  --invest-bg:       var(--dark-blue);
  --cofound-bg:      var(--dark-blue);
  --collaborate-bg:  var(--dark-blue);

  --xl: 1400px;
  --lg: 1280px;
  --md: 768px;
  --sm: 640px;
  --xs: 320px;

  --spacing:                40px;
  --header-width:           1400px;
  --container-width:        1200px;
  --padded-container-width: calc(var(--container-width) + var(--spacing) + var(--spacing));
}
